import PaymentService from '@/services/payment.service.js'
import InfoBankTransfer from '../../Common/InfoBankTransfer/InfoBankTransfer.vue'
import getEnv from '@/util/env'
import { popupCenter } from '@/util/Popup.js'

export default {
  name: 'ConfirmBankTransfer',
  components: {
    InfoBankTransfer,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      showInvoice: false,
      cant: 1,
      subTotal: 0,
      subTotalIva: 0,
    }
  },
  computed: {
    paymentIvaLine () {
      const vatDivision = this.payment.paymentDetail?.infoBilling?.billingProfiles[0]?.vatDivision
      if (vatDivision) {
        let line = ''
        for (const [vatDivisionName, vatDivisionValue] of Object.entries(vatDivision)) {
          line += vatDivisionName + ' ' + vatDivisionValue + '% + '
        }
        return line.slice(0, -2)
      } else {
        return this.payment.invoiceTmp.pc_iva + '% IVA'
      }
    },
    billingCompanySlug () {
      return this.payment.paymentDetail?.infoBilling?.billingProfiles?.find(b => b.is_default)?.billingCompany.slug
    },
  },
  methods: {
    cancelStep () {
      this.payment.step = 2
    },
    goStepInitial () {
      this.payment.reset()
      this.showInvoice = false
      this.payment.step = 1
    },
    invoicePayment () {
      this.callbackBankPaymentMethod()
    },
    calculeSubtotals (amount, cant, iva) {
      this.subTotal = parseFloat(cant) * parseFloat(amount)
      this.subTotalIva = (parseFloat(this.subTotal) * parseFloat(iva)) / 100
    },
    callbackBankPaymentMethod () {
      this.loading = true
      const data = {
        invoiceTmp: this.payment.invoiceTmp.id,
      }

      PaymentService.callbackBankPaymentMethod(data)
        .then(
          (response) => {
            this.payment.infoBillingIsoCode = response.infoBillingIsoCode
            this.payment.invoice = response.invoice
            this.payment.complete = true
            this.showInvoice = true
          },
          (error) => {
            PaymentService.errorResponse(
              error.response.status,
              this.$t('Error al intentar añadir saldo'),
              this.$t(error.response.data.code),
            )
          },
        )
        .finally(() => {
          this.loading = false
        })
    },
    showInvoiceDetail () {
      const user = JSON.parse(localStorage.getItem('user'))
      popupCenter(getEnv('backend_endpoint') + 'api/billing/invoice/' + this.payment.invoice.id + '/pdf?locale=' + user.lang + '&token=' + user.token, this.payment.invoice.description, 800, 600)
    },
    downloadInvoiceDetail () {
      const user = JSON.parse(localStorage.getItem('user'))
      window.location.href = getEnv('backend_endpoint') + 'api/billing/invoice/' + this.payment.invoice.id + '/pdf?token=' + user.token + '&download=1&locale=' + user.lang
    },
  },
  watch: {
    payment: {
      handler: function () {
        this.calculeSubtotals(
          this.payment.invoiceTmp.amount,
          this.cant,
          this.payment.invoiceTmp.iva,
        )
      },
      deep: true,
    },
  },
}
