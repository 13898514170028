import getEnv from '@/util/env'
import { popupCenter } from '@/util/Popup.js'
import PaymentService from '@/services/payment.service'
import InfoCreditCard from '../../Common/InfoCreditCard/InfoCreditCard.vue'
import EventBus from '../../../../../../util/EventBus'

export default {
  name: 'ConfirmCreditCard',
  components: {
    InfoCreditCard,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      showRedirect: false,
      cant: 1,
      subTotal: 0,
      subTotalIva: 0,
      promo: '',
      promotional_code: '',
    }
  },
  watch: {
    payment: {
      handler: function () {
        this.calculeSubtotals(
          this.payment.invoiceTmp.amount,
          this.cant,
          this.payment.invoiceTmp.iva,
        )
      },
      deep: true,
    },
    locale () {
      const params = {
        promo: this.promo,
        amount: this.payment.invoiceTmp?.amount || this.payment.amount,
      }
      PaymentService.checkPromotionalCode(params)
      .then(
        (response) => {
          this.promotional_code = response.promo
        },
        () => {
          this.promotional_code = null
          EventBus.$emit('showAlert', 'danger', this.$t('El código promocional no es válido'))
        },
      )
    },
  },
  computed: {
    locale () {
      return this.$i18n.locale
    },
    paymentIvaLine () {
      const vatDivision = this.payment.paymentDetail?.infoBilling?.billingProfiles[0]?.vatDivision
      if (vatDivision) {
        let line = ''
        for (const [vatDivisionName, vatDivisionValue] of Object.entries(vatDivision)) {
          line += vatDivisionName + ' ' + vatDivisionValue + '% + '
        }
        return line.slice(0, -2)
      } else {
        return this.payment.invoiceTmp.pc_iva + '% IVA'
      }
    },
    hasReferrer () {
      const user = JSON.parse(localStorage.getItem('user'))
      return user.referrer && Array.isArray(user.referrer) === false
    },
  },
  methods: {
    cancelStep () {
      this.payment.step = 2
    },
    goStepInitial () {
      this.payment.reset()
      this.payment.showInvoice = false
      this.payment.step = 1
    },
    viewUrlPay () {
      this.loading = true
      this.showRedirect = true
      setTimeout(function (scope) {
        scope.loading = false
        window.location.href = scope.payment.urlCreditCardPay
      }, 1000, this)
    },
    calculeSubtotals (amount, cant, iva) {
      this.subTotal = parseFloat(cant) * parseFloat(amount)
      this.subTotalIva = (parseFloat(this.subTotal) * parseFloat(iva)) / 100
    },
    showInvoiceDetail () {
      const user = JSON.parse(localStorage.getItem('user'))
      popupCenter(getEnv('backend_endpoint') + 'api/billing/invoice/' + this.payment.invoice.id + '/pdf?locale=' + user.lang + '&token=' + user.token, this.payment.invoice.description, 800, 600)
    },
    downloadInvoiceDetail () {
      const user = JSON.parse(localStorage.getItem('user'))
      window.location.href = getEnv('backend_endpoint') + 'api/billing/invoice/' + this.payment.invoice.id + '/pdf?token=' + user.token + '&download=1&locale=' + user.lang
    },
    addPromoCode () {
      const params = {
        promo: this.promo,
        amount: this.payment.invoiceTmp?.amount || this.payment.amount,
      }
      PaymentService.checkPromotionalCode(params)
      .then(
        (response) => {
          this.promotional_code = response.promo
          this.confimCreditCardPaymentMethod()
        },
        () => {
          this.promotional_code = null
          EventBus.$emit('showAlert', 'danger', this.$t('El código promocional no es válido'))
        },
      )
    },
    confimCreditCardPaymentMethod () {
      const data = {
        amount: this.parseAmount(this.payment.amount),
        infobillingId: this.payment.paymentDetail.infoBilling.billingProfiles[0].id,
        subscribed_card: this.payment.creditCardOptions.subscribed_card,
        rememberCreditCard: this.payment.creditCardOptions.rememberCreditCard,
        promo: this.promo,
        invoiceTmpId: this.payment.invoiceTmp.id,
      }
      PaymentService.confimCreditCardPaymentMethod(data)
        .then(
          (response) => {
            this.payment.invoiceTmp = response.invoiceTmp
            this.payment.urlCreditCardPay = response.url
          },
          (error) => {
            console.log('error', error)
          },
        )
    },
    parseAmount (amount) {
      return amount.replace(',', '.')
    },
  },
}
